* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Gilroy";
  /* @apply transition-colors duration-500; */
}

.slick-slide > div {
  display: flex;
  /* justify-content: space-between; */
  justify-content:center;
  width: 100%;
}
.pool-slider .slick-slide {
  padding-inline: 20px;
}
.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: none; /* Customize the color as per your design */
}
.custom-dot {
  position: absolute;
  bottom: -20px;
}